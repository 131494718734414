<template>
  <main class="no-main">
    <div class="ps-breadcrumb">
      <div class="container-fluid-sy">
        <ul class="ps-breadcrumb__list">
          <li class="active"><a href="#">Home</a></li>
          <li><a href="javascript:void(0);">Login</a></li>
        </ul>
      </div>
    </div>
    <section class="section--login">
      <div class="container">
        <div class="row">
          <div class="col-sm-6 form-group--block offset-sm-3">
            <div class="login__box">
              <div class="login__header">
                <h3 class="login__login">Reset Password</h3>
              </div>
              <div class="login__content">
                <div class="login__label"></div>
                <form @submit.prevent="resetPwd()">
                  <div class="input-group">
                    <input
                      class="form-control"
                      type="email"
                      placeholder="Email address"
                      v-model="form.email"
                    />
                  </div>

                  <div class="input-group group-password">
                    <input
                      class="form-control"
                      type="password"
                      placeholder="New password"
                      v-model="form.password"
                    />
                  </div>

                  <div class="input-group group-password">
                    <input
                      class="form-control"
                      type="password"
                      placeholder="Confirm password"
                      v-model="form.password_confirmation"
                    />
                  </div>
                  <button class="btn btn-login" type="submit">Reset</button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>
<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      form: {
        token: "",
        email: "",
        password: "",
        password_confirmation: "",
      },
    };
  },

  mounted() {
    if (this.$route.params) {
      if (this.$route.params.token) {
        this.form.token = this.$route.params.token;
      }
    }
  },

  methods: {
    ...mapActions("auth", ["resetPassword"]),

    resetPwd() {
      this.resetPassword(this.form).then(() => {
        this.$router.push({ name: "login" });
      });
    },
  },
};
</script>
